// src/DataList.js
import React, { useState, useEffect } from 'react';

const DataList = () => {
  const [data, setData] = useState([]);  // State to hold fetched data
  const [loading, setLoading] = useState(false);  // State to track loading status
  const [error, setError] = useState(null);  // State to handle errors
  const [date, setDate] = useState('');  // State to hold the selected date

  useEffect(() => {
    if (date) {  // Only fetch data if a date is selected
      setLoading(true);
      const fetchData = async () => {
        try {
          const response = await fetch(`http://47.99.145.81:8000/orders?order_date=${date}`);
          if (!response.ok) {
            throw new Error('Failed to fetch data');
          }
          const result = await response.json();
          setData(result);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [date]);  // Run effect whenever 'date' changes

  const handleDateChange = (event) => {
    setDate(event.target.value);  // Update date state when user selects a date
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h1>订单信息</h1>
      <input
        type="date"
        value={date}
        onChange={handleDateChange}
        placeholder="Select a date"
      />
      <table style={{ width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ backgroundColor: '#f2f2f2' }}>
            <th style={{ border: '1px solid black', padding: '8px' }}>运单号</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>寄件人姓名</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>寄件人电话</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>寄件人地址</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>收件人姓名</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>收件人电话</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>收件人地址</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td style={{ border: '1px solid black', padding: '8px' }}>{item.order_id}</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>{item.sender_name}</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>{item.sender_phone}</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>{item.sender_address}</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>{item.receiver_name}</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>{item.receiver_phone}</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>{item.receiver_address}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataList;
