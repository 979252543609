// src/App.js
import React from 'react';
import OrderList from './OrderList';  // Import the DataList component

function App() {
  return (
    <div className="App">
      <OrderList />  {/* Render the DataList component */}
    </div>
  );
}

export default App;
